import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import InputField from "../../components/InputField";
import handleImageUpload from "../../utils/handleImageUpload";
import requestApiFormData from "../../utils/requestApiFormData";
import ShowAlert from "../../components/ShowAlert";

import "../../styles/RegisterInterest.scss";
import "../../styles/CustomForm.scss";

import attach from "../../assets/svg/attach.svg";
import paper from "../../assets/svg/paper.svg";
import close_silver from "../../assets/svg/close-silver.svg";

const SupportForm = () => {
  const uploadButtonRef = useRef();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [types, setTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [load, setLoad] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: "",
      type: "",
      message: "",
    },
  });

  const validatePhone = async (value) => {
    return isValidPhoneNumber(value);
  };

  const handleFileChange = (event) => {
    setFiles([...files, ...event?.target?.files].slice(0, 5));
  };

  const handleRemoveFile = (index) => {
    const newImages = [...files];
    newImages.splice(index, 1);
    setFiles(newImages);
  };

  const onSubmit = async (data) => {
    if (load) return false;

    setLoad(true);

    data["reason_id"] = data?.type?.value;
    data["subject"] = data?.message?.slice(0, 14);
    data["description"] = data?.message;
    delete data.type;

    requestApiFormData("/support/add-message", data)
      .then(
        ({
          data: {
            request: { token },
            messages,
          },
          status,
        }) => {
          if (!status) return null;

          if (!token) return false;

          if (files.length && Object.keys(messages)[0])
            handleImageUpload(files, token, Object.keys(messages)[0])
              .then(() => {
                ShowAlert(
                  "success",
                  "Your request has been created.",
                  enqueueSnackbar
                );
                navigate(`/support/${token}`);
              })
              .catch(() =>
                ShowAlert("error", "Unknown error!", enqueueSnackbar)
              )
              .finally(() => setLoad(false));
          else {
            ShowAlert(
              "success",
              "Your request has been created.",
              enqueueSnackbar
            );
            navigate(`/support/${token}`);
          }
        }
      )
      .catch((err) => {
        setLoad(false);
        ShowAlert("error", "Unknown error!", enqueueSnackbar);
      });
  };

  useEffect(() => {
    setTypes([
      { value: 1, label: "Support" },
      { value: 2, label: "Contact administration team" },
      { value: 3, label: "Contact sales team" },
    ]);
  }, []);

  return (
    <>
      <div className="register_interest">
        <div className={"ellipse-container"}>
          <div className={"ellipse-first"} />
          <div className={"ellipse-second"} />
          <div className={"ellipse-third"} />
        </div>

        <div className={"register-container"}>
          <div className={"information"}>
            <h1>Reach us out!</h1>

            <h2>
              We'd love to hear from you! Whether an event organizer, investor
              or potential user, reach customer relations representative
              directly!
            </h2>
          </div>

          <div className={"custom-form"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={"inputs-two"}>
                <InputField
                  name="phone"
                  control={control}
                  rules={{
                    validate: validatePhone,
                  }}
                  label="Phone"
                  placeholder="+44 123 456 78 90"
                  errors={errors}
                />

                <InputField
                  select={true}
                  options={types}
                  name="type"
                  control={control}
                  rules={{
                    required: true,
                    validate: (type) =>
                      parseFloat(type?.value) > 0 || "Please select type",
                  }}
                  label="Type"
                  errors={errors}
                />
              </div>

              <InputField
                name="message"
                control={control}
                rules={{ required: true, minLength: 10 }}
                label="Description"
                placeholder="...Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
                errors={errors}
                textarea
              />

              {files?.length > 0 && (
                <div className="files-container">
                  {Array.from(files)?.map((file, index) => (
                    <div className="file" key={index}>
                      <img src={paper} />

                      <span>{file?.name}</span>

                      <img
                        src={close_silver}
                        onClick={() => handleRemoveFile(index)}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className={"button space-between"}>
                <div>
                  <div
                    className="attach-button"
                    onClick={() => uploadButtonRef?.current?.click()}
                  >
                    <img src={attach} />

                    <span>Attach file</span>
                  </div>

                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf,.mp4"
                    multiple
                    onChange={handleFileChange}
                    ref={uploadButtonRef}
                    style={{ display: "none" }}
                  />
                </div>

                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportForm;
